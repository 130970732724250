import { updateArrInStore } from '@/store/storeHelpers';
import { activities } from '../../../views/groups/form/groupHelpers';

export default {
  setContacts: (state, contacts) => {
    state.contacts = contacts;
  },
  setContactsLoading: (state, loading) => {
    state.contactsLoading = loading;
  },
  setContactsInfo: (state, contactsInfo) => {
    state.contactsInfo = contactsInfo;
  },
  setContactLoading: (state, loading) => {
    state.contactLoading = loading;
  },
  setActiveContact: (state, contact) => {
    state.activeContact = contact;
  },
  updateContactInList: (state, contact) => {
    updateArrInStore(state, contact, 'contacts');
  },

  setSuggestionsLoading: (state, value) => {
    state.suggestionsLoading = value;
  },

  setBoardsLoaded: (state, value) => {
    state.boardsLoaded = value;
  },

  setBoards: (state, value) => {
    state.boards = value;
  },

  setBoardsConfig: (state, config) => {
    state.boardsConfig = config;
  },

  updateScrollDepth: (state, value) => {
    state.contactsScrollDepth = value;
  },
};
