<template>
  <v-form ref="form">
    <form-field
      v-for="(field, key) in contactSchema.main"
      :key="key"
      class="mb-3"
      v-model="contact[key]"
      :schema="field"
      :field="key"
    />

    <v-expansion-panels
      v-if="!profilePid"
      class="custom-panel content-panel"
      accordion
      v-model="panel"
    >
      <v-expansion-panel expand>
        <v-expansion-panel-header>
          <content-line text="Show More Fields" icon="campaigns/more" />
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="pa-4 additional-fields">
            <form-field
              v-for="(field, key) in contactSchema.additional"
              :key="key"
              class="mb-3"
              v-model="contact[key]"
              :schema="field"
              :field="key"
            />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex';

import { FIELD_TYPE_MAP } from '@/views/settings/fields/fieldConstants';

import startCase from 'lodash/startCase';

import FormField from '@/components/formFields/FormField';

export default {
  name: 'ContactFormFields',
  components: {
    FormField,
  },
  data() {
    return {
      panel: [],
    };
  },
  props: {
    contact: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters(['profilePid']),
    ...mapGetters('fields', {
      fields: 'all',
    }),
    ...mapGetters('teams', ['currentTeam']),

    mainFields() {
      return ['first_name', 'last_name', 'email', 'mobile'];
    },

    contactSchema() {
      if (!this.contact) return [];

      const defaultFields = this.fields.find(({ type }) => {
        return type === 'default';
      });

      const fieldIsHidden = (field) => {
        const forbiddenTypes = ['date', 'time', 'secret'];

        if (
          this.visibleContactFields.length &&
          !this.visibleContactFields.includes(field.name)
        ) {
          return true;
        }

        return field.form_hidden || forbiddenTypes.includes(field.type);
      };

      return Object.entries(defaultFields.fields).reduce(
        (acc, [key, field]) => {
          if (fieldIsHidden(field)) return acc;

          // Set default value if defined
          if (field.default_value) {
            this.contact[key] = field.default_value;
          }

          // Determine field schema and group
          const schema = this.getFieldSchema(field, key, defaultFields.id);
          const isMainField = this.mainFields.includes(key) || this.profilePid;

          const fieldGroup = isMainField ? 'main' : 'additional';

          acc[fieldGroup][key] = schema;
          return acc;
        },
        { main: {}, additional: {} }
      );
    },

    visibleContactFields() {
      const contactFields = this.currentTeam?.contact_fields || [];
      if (!contactFields?.length) return contactFields;

      return [...this.mainFields, ...contactFields];
    },
  },
  methods: {
    getFieldSchema(field, key, bucketId) {
      const schema = {
        title: this.getTitle(field),
        type: this.getType(field),
        label: this.getTitle(field),
        field: key,
        name: field.name,
        bucketId: bucketId,
        filters: field.filters,
        contactId: '',
        validators: field.label === 'name' ? ['required'] : '',
        left: field.type === 'bool',
        options: field.values.map((i) => {
          return { text: i, value: i };
        }),
        originalType: field.type,
      };

      if (field.type === 'related_custom_object') {
        schema.custom_object_id = field.custom_object_id;
        schema.custom_object_field = field.custom_object_field;
      }

      return schema;
    },

    getTitle(field) {
      let label = field.label === 'name' ? field.name : field.label;
      return startCase(label.replace(/_/g, ' '));
    },

    getType(field) {
      if (field.values && field.values.length && field.type !== 'list') {
        return 'Select';
      }
      return FIELD_TYPE_MAP[field.type];
    },

    validate() {
      return this.$refs.form.validate();
    },

    reset() {
      return this.$refs.form.reset();
    },
  },
};
</script>

<style scoped lang="less">
.field-block + .field-block {
  margin-top: 20px;
}

.additional-fields {
  background-color: var(--v-darkGray-base);
}
</style>
