import { apiPath, getHeaders } from '@/globals';
import Axios from 'axios';
import store from '@/store';
import localStore from 'store';
import EventBus from '@/factories/eventBus';
import { trackEvent } from '@/lib/analytics';
import isEmpty from 'lodash/isEmpty';
import qs from 'qs';

const api = (config) => {
  const axios = Axios.create(createConfig(config));

  axios.interceptors.request.use((config) => {
    if (config.url.startsWith('cg/')) {
      delete config?.params?.teams;
    }

    if (config?.params) {
      const { teams, ...params } = config?.params || {};
      if (!isEmpty(params)) {
        const sign = config.url.includes('?') ? '&' : '?';
        config.url += `${sign}${qs.stringify(config?.params)}`;
      }
      config.params = { teams };
    }

    if (store.getters['profilePid']) {
      if (
        ['fields', 'contacts', 'custom'].some((url) =>
          config.url.startsWith(url)
        )
      ) {
        config.url = 'portal/' + config.url;
      }
    }

    return config;
  });

  axios.interceptors.response.use(
    (response) => {
      return onSuccessResponse(response);
    },
    ({ response }) => {
      return onErrorResponse(response);
    }
  );

  return axios;
};

const onSuccessResponse = (response) => {
  store.dispatch('users/checkUserActivity', response || {});
  const { data, status, config } = response || {};
  // check plan overage
  if (status === 429) {
    EventBus.$emit('showPlanOverage', data);
    // check other errors
  } else if (data?.status === 'failed') {
    if (data?.message && !config.returnMessage) {
      handleResponseError(response);
    }
    throw response;
  }

  // we need full response for portal auth;
  if ((config?.url || '').startsWith('cg/auth') || config?.returnMessage) {
    return data;
  }

  // an empty data array should still return an the empty data array
  // without this check, we have issues where stores are holding all
  const emptyDataArray = Array.isArray(data?.data) && data.data.length === 0;

  if (isEmpty(data?.data) && !emptyDataArray && data?.message) {
    return data;
  }

  return data?.data || data;
};

const onErrorResponse = (response) => {
  const { data, status } = response || {};
  if (data?.message) {
    handleResponseError(response);
  }

  // handle auth errors for portal
  if (isAuthError(status) && !data?.data?.permission) {
    EventBus.$emit('authFailed');
  }

  return Promise.reject(response);
};

const isAuthError = (status) => {
  return [401, 403].includes(status);
};

const handleResponseError = ({ data, config, status }) => {
  store.commit('showInfoModal', { type: 'error', data, status });
  if (data && data.data && data.data.track === false) {
    return;
  }
  trackEvent('error', { ...data, ...config });
};

const getAuthFromStoredProfile = () => {
  const profile = store.getters.profile || {};
  if (!profile.pid || !profile.access_token) {
    return {};
  }
  return {
    id: profile.pid,
    token: profile.access_token,
  };
};

const authValid = (auth = {}) => {
  return auth.id && auth.token;
};

const createConfig = (settings) => {
  const headers = getHeaders(settings?.headers);

  if (settings?.headers) {
    delete settings.headers;
  }

  let config = {
    baseURL: apiPath,
    headers,
    params: {},
    ...settings,
  };

  const pid = store.getters?.profilePid;
  if (pid) {
    config.headers['conco-pid'] = pid;
  }

  const localPin = localStore.get('conveyour-pin');
  if (localPin) {
    config.headers['x-conveyour-pin'] = localPin;
  }

  // first check for the existance of a keyed auth that matches the portal we are on.
  // This is helpful for testing lessons with dummy contact without changing the auth
  // for the portal we are already on.
  // Since we only ever set the auth_:{pid} when are using Mary McTesterson, dummy contact
  // this is a manageable solution for having two contexts.
  // We only set the auth_:{pid} when testing lessons via [Test] in the lesson builder
  let auth = {};

  if (pid) {
    const testingAuth = localStore.get(`auth_${pid}`) || {};
    if (testingAuth.id && testingAuth.token) {
      auth = testingAuth;
    }
  }

  // an attempt to fix the issue where local storage auth fails to load from localStorage
  // can read about it more here. https://www.notion.so/throwbo/d33d8c28cc9f4095b4411c677ad14630
  if (!authValid(auth)) {
    auth = localStore.get('portal_auth') || getAuthFromStoredProfile() || {};
    if (auth.id && auth.token) {
      localStore.set('portal_auth', auth);
    }
  }

  if ((auth.id === pid || !pid) && auth.token) {
    config.headers['Authorization'] = `Bearer ${auth.token}`;
  }

  if (!auth.token && config.headers['Authorization']) {
    delete config.headers['Authorization'];
  }

  //CORS with api auth headers is not going to pass cookies
  //anyways, so csrf token will not return in /api/users/current

  //but if some how the csrf token did get added as a header somehow
  //CY's backend would rejext because x-csrf-token is not a header
  //permitted by CORS
  // some of ConveYour's controllers like users controller filter on CSRF
  // on put, post, delete requests so we add the CSRF if we are using sessions
  if (!config?.headers['x-conveyour-appkey']) {
    let user = store.getters['users/currentUser'];
    if (user && user.csrf_token) {
      config.headers['X-CSRF-TOKEN'] = user.csrf_token;
    }
  }

  if (config.shareToken) {
    config.headers['Authorization'] = `Bearer ${config.shareToken}`;
    delete config.shareToken;
  }

  const teams = getTeams();
  if (teams.length && !config.teamFilterOff) {
    config.params = {
      ...config.params,
      teams,
    };
    config.data = { teams };
  }

  if (config.CSRFToken) {
    config.headers['X-CSRF-TOKEN'] = config.CSRFToken;
  }

  return config;
};

const getTeams = () => {
  const userTeamId = store.getters['users/userTeam'];
  return userTeamId ? [userTeamId] : [];
};

export default api;
