var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"styled-modal",class:{
    'fixed-modal d-flex': _vm.fixed,
    'styled-modal--portal': _vm.portal,
  },on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.$emit('close')}}},[_c('v-card-title',[(_vm.hasActionsSlot)?_c('v-btn',{staticClass:"mr-2",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',{attrs:{"color":"light"}},[_vm._v("close")])],1):_vm._e(),_c('h4',{staticClass:"light--text"},[_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.help)?_c('help-link',{staticClass:"ml-2",attrs:{"route":_vm.help}}):_vm._e()],1),_c('v-spacer'),_vm._t("headerActions",function(){return [(_vm.closable)?_c('v-btn',{staticClass:"mr-2",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',{attrs:{"color":"light"}},[_vm._v("close")])],1):_vm._e()]})],2),_c('v-card-text',{staticClass:"styled-modal__content",class:{
      'fixed-modal__content': _vm.fixed,
      'fixed-modal__content--full': _vm.full,
      light: _vm.light,
      'pa-4': _vm.padding,
    }},[(_vm.loading)?_c('div',{staticClass:"spinner-wrap pa-5"},[_c('spinner')],1):[_vm._t("default")]],2),(!_vm.full)?_c('v-card-actions',{staticClass:"pa-3 pt-2 pb-2"},[_vm._t("leftActions"),(_vm.showActionsSpacer)?_c('v-spacer'):_vm._e(),(_vm.closeButton && _vm.closable)?_c('v-btn',{staticClass:"ml-4",attrs:{"text":"","color":"appMain"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Cancel")]):_vm._e(),_vm._t("actions")],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }