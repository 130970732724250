<template>
  <div class="form-group" :class="[componentName]">
    <component
      :is="fieldComponent"
      :item="item"
      :meta="meta"
      :schema="schema"
      :field="field"
      :rules="rules"
      :disabled="disabled"
      :lessonId="lessonId"
      :errorMessages="errorMessages"
      :successMessages="successMessages"
      v-model="computedValue"
      @customEvent="handleCustomEvent"
    />
  </div>
</template>

<script>
import validationRules from '@/lib/validationRules';

export default {
  name: 'FormField',
  props: ['item', 'field', 'schema', 'meta', 'value', 'disabled', 'lessonId'],
  data() {
    return {
      errorMessages: [],
      successMessages: [],
      fieldComponent: null,
    };
  },
  watch: {
    componentName: {
      immediate: true,
      handler(val, oldVal) {
        if (val === oldVal) return;
        this.loadComponent();
      },
    },
  },
  computed: {
    componentName() {
      return this.getComponentName();
    },
    computedValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    rules() {
      let rulesArr = [];
      let validators = this.schema?.validators || [];

      if (!validators || !validators.length) {
        return rulesArr;
      }

      validators.forEach((rule) => {
        let type = rule.charAt(0).toUpperCase() + rule.slice(1);
        if (validationRules[`check${type}`]) {
          rulesArr.push((v) => {
            const result = validationRules[`check${type}`].call(this, v);
            if (['object', 'undefined'].includes(typeof result)) return true;
            return result;
          });
        }
      });
      return rulesArr;
    },
  },
  methods: {
    loadComponent() {
      if (!this.componentName) {
        this.fieldComponent = '';
      }
      this.fieldComponent = () => import(`./${this.componentName}`);
    },
    getComponentName() {
      if (!this.schema) return '';

      const { group, type, fieldTypeahead } = this.schema || {};

      if (fieldTypeahead && !this.item && type === 'TextArea') {
        return 'TributeField';
      }

      if (group === 'tags' && type === 'List') {
        return 'TagsField';
      }

      if (type === 'Banner') {
        return 'ImageField';
      }

      if (type === 'KeyValues') {
        return 'AnswersField';
      }

      return type + 'Field';
    },
    handleCustomEvent({ type, value }) {
      this.$emit(type, value);
    },
  },
};
</script>

<style lang="less">
.field__inner-title {
  margin-bottom: 8px;
  div {
    font-weight: 900;
    font-size: 14px;
    line-height: 16px;
    color: var(--v-appMain-base);
    margin-bottom: 8px;
    display: flex;
    align-items: center;
  }
  p {
    margin: 0;
  }
}

.field-block__title ~ .form-group {
  .field__inner-title {
    display: none;
  }
}

.autocomplete-field__chips {
  padding: 1px;
  cursor: pointer;
  min-height: 30px;
  max-height: 98px;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin: 0;
  border-radius: @base-radius;

  &.filled {
    margin: -1px 0 -3px 0;
  }

  .v-chip {
    margin: 4px;
  }

  &:hover {
    background: var(--v-lightGray-base);
  }
}

.autocomplete-field {
  .v-input {
    margin: 5px 0 4px 1px !important;
    min-height: 20px;

    &:not(.v-input--is-dirty) {
      margin-bottom: 2px !important;
    }

    .v-chip--active {
      background: var(--v-appMain-base);
      color: #fff;
    }
  }
}
</style>
