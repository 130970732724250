import api from '@/factories/api';

import map from 'lodash/map';
import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import { asyncForEach } from '@/lib/misc';
import { onParent } from '@/lib/parentConnection';

export default {
  async loadCourses({ commit, state }, { schemaLevel }) {
    if (state.loadingCourses !== null) {
      return false;
    }

    commit('setLoadingCourses', true);

    try {
      const courses = await api().get(`cg/campaigns?schema=${schemaLevel}`);
      const storeCoursesClone = map(state.courses, clone);
      commit('setCourses', {
        courses: mergeArrays(storeCoursesClone, courses),
        schemaLevel,
      });
    } catch (error) {
      throw error;
    } finally {
      commit('setLoadingCourses', false);
    }
  },

  async loadSingleCourse(
    { dispatch, commit, state },
    { id, schemaLevel, forceReload, background }
  ) {
    const course = state.courses.find((i) => i.id === id);
    if (course && course.schemaLevel === 'full' && !forceReload) {
      return false;
    }

    if (!background) {
      commit('setLoadingSingleCourse', true);
    }

    try {
      schemaLevel = schemaLevel || 'full';
      let path = `cg/campaigns/${id}?schema=${schemaLevel}`;

      let course = await api().get(path);
      course = course[0] || {};
      course.schemaLevel = schemaLevel;

      dispatch('updateCourses', course);
    } catch (error) {
      throw error;
    } finally {
      if (!background) {
        commit('setLoadingSingleCourse', false);
      }
    }
  },

  async syncCourseUpdates({ dispatch, commit, state }, { triggers }) {
    await asyncForEach(triggers, (trigger) => {
      //TODO, check to make sure this runs synchronously...
      if (trigger.type === 'join') {
        return dispatch('loadSingleCourse', {
          id: trigger.join_campaign_id,
          forceReload: true,
          background: true,
        });
      }

      //don't really know what to do here.
      let course = state.courses.find((c) => c.id === trigger.campaign_id);
      if (!course) {
        return false;
      }

      course = cloneDeep(course);

      //fix indexing?
      let courseTriggers = course.triggers || [];

      let existingTrigger = courseTriggers.find((t) => t.id === trigger.id);
      if (!existingTrigger) {
        courseTriggers.push(trigger);
      }

      course.triggers = courseTriggers;

      dispatch('updateCourses', course);
    });
  },

  async joinCourse({ dispatch, commit }, data) {
    commit('setLoadingSingleCourse', true);
    try {
      await api().post('cg/join', data);

      dispatch('loadSingleCourse', {
        id: data.campaign_id,
        forceReload: true,
      });
    } catch (error) {
      throw error;
    } finally {
      commit('setLoadingSingleCourse', false);
    }
  },

  finishCourseLesson({ dispatch, state }, item) {
    const course = state.courses.find((i) => i.id === item.campaign_id);
    const lesson = (course.triggers || []).find((i) => i.id === item.id);

    if (!lesson || !course) return;

    if (!lesson.finished) {
      dispatch('updateCourses', {
        ...course,
        triggers: course.triggers.map((i) =>
          i.id === lesson.id ? { ...i, finished: true } : i
        ),
      });
    }

    dispatch('updateScormCourseState', item.campaign_id);
  },

  updateScormCourseState({ getters, state, rootGetters }, courseId) {
    if (!rootGetters.insideScorm) return false;

    onParent('setCourseProgress', (action) =>
      action({
        last_seen: new Date().getTime(),
      })
    );

    const lessonCourse = state.courses.find((i) => i.id === courseId);

    if (!lessonCourse) return;

    const getChildLessons = (lessonId) => {
      return lessonCourse?.triggers.filter(({ parent_trigger }) => {
        return parent_trigger === lessonId;
      });
    };

    const unfinishedLessonsCount = (lessonCourse?.triggers || []).filter(
      (lesson) => {
        const { id, finished, parent_trigger } = lesson;
        return (
          !parent_trigger &&
          (!finished || getChildLessons(id).some((l) => !l.finished))
        );
      }
    ).length;

    if (!unfinishedLessonsCount) {
      onParent('courseCompleted', (action) => action());
    }
  },

  updateCourses({ commit, state }, course) {
    const courses = map(state.courses, clone);
    const courseIndex = courses.findIndex((i) => i.id == course.id);
    if (courseIndex === -1) {
      courses.push(course);
    } else {
      courses[courseIndex] = course;
    }
    commit('setCourses', { courses });
  },

  async loadBillingInfo({ dispatch, commit }, course) {
    commit('setLoadingSingleCourse', true);
    try {
      const billingInfo = await api().get(
        `cg/billing?campaign_id=${course.id}`
      );
      const courseCopy = Object.assign(cloneDeep(course), billingInfo);
      dispatch('updateCourses', courseCopy);
    } catch (error) {
      throw error;
    } finally {
      commit('setLoadingSingleCourse', false);
    }
  },

  async cancelSubscription({ dispatch }, course) {
    try {
      const billingInfo = await api().post('cg/cancel_subscription', {
        campaign_id: course.id,
      });
      const courseCopy = Object.assign(cloneDeep(course), { status: 'shown' });
      dispatch('updateCourses', courseCopy);
    } catch (error) {
      throw error;
    }
  },

  async loadPortalFolders({ commit, state }) {
    if (state.portalFoldersLoaded) return;

    try {
      const folders = await api().get('cg/lessons_folders');
      commit('setPortalFolders', folders);
    } catch (error) {
      throw error;
    } finally {
      commit('setPortalFoldersLoaded', true);
    }
  },

  async loadPortalFolderLessons({ commit }, folderId) {
    commit('setPortalFolderLessonLoading', true);
    try {
      const res = await api().get(`cg/lessons_folders/${folderId}?schema=full`);
      const folder = Array.isArray(res) ? res[0] : '';
      commit('updatePortalFolder', folder);
    } catch (error) {
      throw error;
    } finally {
      commit('setPortalFolderLessonLoading', false);
    }
  },

  updateFolderLesson({ commit, state }, { folderId, lesson }) {
    const folder = cloneDeep(
      state.portalFolders.find((i) => i.id === folderId)
    );
    const lessonIdx = folder.lessons.findIndex((l) => l.id === lesson.id);
    folder.lessons.splice(lessonIdx, 1, lesson);
    commit('updatePortalFolder', folder);
  },
};

const mergeArrays = (rootArr, newArr) => {
  return newArr.reduce(
    (acc, course) => {
      if (rootArr.findIndex((i) => i.id === course.id) === -1) {
        acc.push(course);
      }
      return acc;
    },
    [...rootArr]
  );
};
