export const FIELD_TYPES = [
  {
    text: 'Number',
    value: 'int',
  },
  {
    text: 'True/False',
    value: 'bool',
  },
  {
    text: 'Text',
    value: 'string',
  },
  {
    text: 'Textarea',
    value: 'textarea',
  },
  {
    text: 'Email',
    value: 'email',
  },
  {
    text: 'Date',
    value: 'date',
  },
  {
    text: 'Time',
    value: 'time',
  },
  {
    text: 'List',
    value: 'list',
  },
  {
    text: 'Related Contacts',
    value: 'related_contacts',
  },
  {
    text: 'Image',
    value: 'image',
  },
  {
    text: 'ID Number',
    value: 'id_number',
  },
  {
    text: 'Secret',
    value: 'secret',
  },
  {
    text: 'Related Custom Object',
    value: 'related_custom_object',
  },
];

export const ROLES = [
  { text: 'Admins Only', value: 'admin' },
  { text: 'Admins & Guests', value: 'guest' },
];

export const SHARE_EDITABLE = [
  { text: 'No', value: '0' },
  { text: 'Yes', value: '1' },
];

export const FIELD_CONVERSION_MAP = {
  int: ['string', 'secret'],
  bool: ['int', 'string'],
  string: ['int', 'textarea', 'email', 'secret'],
  textarea: ['string'],
  email: ['string'],
  date: ['string', 'time', 'int'],
  time: ['string', 'date', 'int'],
  image: [],
  list: [],
  related_contacts: [],
  related_custom_object: [],
  secret: [],
  id_number: [],
};

export const FIELD_TYPE_MAP = {
  bool: 'Checkbox',
  date: 'Date',
  image: 'Image',
  id_number: 'Number',
  int: 'Number',
  list: 'ContactList',
  related_contacts: 'ContactList',
  relation: 'ContactList',
  related_custom_object: 'RelatedCustomObject',
  string: 'Text',
  email: 'Text',
  textarea: 'TextArea',
  time: 'DateTime',
  secret: 'Secret',
};
