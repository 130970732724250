export default {
  contacts: (state) => {
    return state.contacts || [];
  },
  contactsLoading: (state) => {
    return state.contactsLoading;
  },
  contactsInfo: (state) => {
    return state.contactsInfo;
  },
  singleContact: (state) => {
    return (contactId) => {
      if (!state.contacts) return null;
      return state.contacts.find((i) => i.con_id === contactId);
    };
  },
  activeContact: (state) => {
    return (contactId) => {
      if (contactId === state.activeContact?.con_id) {
        return state.activeContact;
      }
      return null;
    };
  },
  contactLoading: (state) => {
    return state.contactLoading;
  },
  suggestionsLoading: (state) => {
    return state.suggestionsLoading;
  },
  boardsLoaded: (state) => {
    return state.boardsLoaded;
  },
  boards: (state) => {
    return state.boards;
  },
  boardsConfig: (state) => {
    return state.boardsConfig;
  },

  contactsScrollDepth: (state) => {
    return state.contactsScrollDepth;
  },
};
